import axios from 'axios'

export default class InteractionService {
  public getById(id) {
    return axios.get('/api/v1/interactions/' + id)
  }

  public getAll(
    query?: string,
    fromDate?,
    toDate?,
    repId?,
    rayonId?,
    brickId?,
    productId?,
    tierId?,
    onlyPlanned?,
    userId?,
    labelId?,
    clientId?,
    limit?,
    offset?,
    targeted?,
  ) {
    return axios.get('/api/v1/interactions', {
      params: {
        query,
        fromDate,
        toDate,
        repId,
        rayonId,
        brickId,
        productId,
        tierId,
        onlyPlanned,
        userId,
        labelId,
        clientId,
        limit,
        offset,
        targeted,
      },
    })
  }

  public getByClientIdAndProductId(clientId, productId) {
    return axios.get(
      '/api/v1/clients/' + clientId + '/interactions/products/' + productId,
    )
  }

  public getInteractionsWithoutProductsByClientId(clientId) {
    return axios.get(
      '/api/v1/clients/' + clientId + '/interactions/no-products/',
    )
  }

  public getByProductId(productId) {
    return axios.get('/api/v1/products/' + productId + '/interactions/products')
  }

  public getAllExcel(
    query?: string,
    fromDate?,
    toDate?,
    repId?,
    rayonId?,
    brickId?,
    productId?,
    tierId?,
    onlyPlanned?,
    labelId?,
    targeted?,
  ) {
    return axios.get('/api/v1/interactions/export', {
      responseType: 'blob',
      params: {
        query,
        fromDate,
        toDate,
        repId,
        rayonId,
        brickId,
        productId,
        tierId,
        onlyPlanned,
        labelId,
        targeted,
      },
    })
  }

  public create(interaction: any) {
    return axios.post('/api/v1/interactions', interaction)
  }

  public update(interaction: any) {
    return axios.post(`/api/v1/interactions/${interaction.id}`, interaction)
  }

  public delete(interaction: any) {
    return axios.delete(`/api/v1/interactions/${interaction.id}`)
  }

  public last(userId, cycleId) {
    return axios.get('/api/v1/interactions/get-last', {
      params: {
        userId,
        cycleId,
      },
    })
  }
}
