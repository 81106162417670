<template>
  <div class="table-container">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'TableContainer',
  })
</script>

<style lang="sass">
  .table-container
    display: block
    width: 100%
    overflow-x: auto

    table
      width: 100%
      max-width: 100%

      thead
        th
          text-align: left
          text-transform: uppercase
          font-size: .9rem
          padding: 1rem 1.25rem
          border-bottom: 1px solid rgba(0, 0, 0, .1)

      tbody,
      tfoot
        tr
          td
            padding: 1rem 1.25rem
            white-space: nowrap
            color: #0b183c
            text-decoration: none

          &:hover
            td
              cursor: pointer
              background-color: #f5f5f5

        tr:nth-child(odd)
          background-color: #fafafa

      tfoot
        tr
          td
            border-top: 1px solid rgba(0, 0, 0, .8)
</style>
