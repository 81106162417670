import axios from 'axios'

export default class TargetService {
  public getByUserId(id: number, cycleId: number) {
    return axios.get(`/api/v1/targets/user/${id}`, {
      params: {
        cycleId,
      },
    })
  }
}
