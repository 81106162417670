import axios from 'axios'
import { getClientById } from '@/composables/useClientService'

export default class ClientService {
  public getAll(
    query: string = '',
    limit: number = 50,
    offset: number = 0,
    repId?: number,
    rayonId?: number,
    brickIds?: number[],
    clientTypeIds?: number[],
    clientType2Id?: number,
    productId?: number,
    tierId?: number,
    labelId?: number,
    targeted?: boolean,
    optIn?: boolean,
    visited?: boolean,
    isActive?: boolean,
    properties?: object,
    orderByColumn?: string,
    orderByDirection?: string,
  ) {
    return axios.get('/api/v1/clients', {
      params: {
        query,
        limit,
        offset,
        repId,
        rayonId,
        brickIds,
        clientTypeIds,
        clientType2Id,
        productId,
        tierId,
        labelId,
        targeted,
        optIn,
        visited,
        isActive,
        properties,
        orderByColumn,
        orderByDirection,
      },
    })
  }

  public getAllExcel(
    query: string = '',
    repId?: number,
    rayonId?: number,
    brickId?: number,
    clientTypeId?: number,
    clientType2Id?: number,
    productId?: number,
    tierId?: number,
    labelId?: number,
    targeted?: boolean,
    optIn?: boolean,
    visited?: boolean,
    isActive?: boolean,
    properties?: object,
    orderByColumn?: string,
    orderByDirection?: string,
  ) {
    return axios.get('/api/v1/clients/export', {
      responseType: 'blob',
      params: {
        query,
        repId,
        rayonId,
        brickId,
        clientTypeId,
        clientType2Id,
        productId,
        tierId,
        labelId,
        targeted,
        optIn,
        visited,
        isActive,
        properties,
        orderByColumn,
        orderByDirection,
      },
    })
  }

  public getById(id) {
    return getClientById(id)
  }

  public getSummaryDispensedMaterial(id: number) {
    return axios.get(`/api/v1/client/${id}/summary-dispensed-material`)
  }

  public getSummaryCurrentCycle(id: number) {
    return axios.get(`/api/v1/clients/${id}/summary-current-cycle`)
  }

  public getRecentInteractions(clientId: number, offset?: number) {
    return axios.get(`/api/v1/clients/${clientId}/recent-interactions`, {
      params: {
        offset,
      },
    })
  }

  public getPlannedInteractions(clientId: number, offset?: number) {
    return axios.get(`/api/v1/clients/${clientId}/planned-interactions`, {
      params: {
        offset,
      },
    })
  }

  public addEmail(id: number, email: string, isSecondary: boolean = false) {
    return axios.post('/api/v1/clients/' + id + '/add-email', {
      email,
      isSecondary,
    })
  }

  public consentWithoutSignature(id: number) {
    return axios.post(`/api/v1/clients/${id}/consent-without-signature`)
  }

  public consent(
    id: number,
    signatureDataUri: string,
    consentCheckbox: boolean = false,
  ) {
    return axios.post(`/api/v1/clients/${id}/consent`, {
      signatureDataUri,
      consentCheckbox,
    })
  }

  public changeNote(id: number, note: string) {
    return axios.post(`/api/v1/clients/${id}/note`, {
      note,
    })
  }

  public withdrawConsent(id: number) {
    return axios.delete('/api/v1/clients/' + id + '/consent')
  }

  public delete(id: number) {
    return axios.delete(`/api/v1/clients/${id}`)
  }
}
