<template>
  <header class="page-header">
    <div>
      <slot></slot>
    </div>

    <div class="actions">
      <slot name="actions"></slot>
    </div>
  </header>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'PageHeader',
  })
</script>

<style lang="scss" scoped>
  .page-header {
    display: flex;

    div {
      &.actions {
        display: flex;
        align-items: start;
        justify-content: flex-end;
        gap: 8px;
        flex-grow: 1;

        * {
          flex-shrink: 0;
        }
      }
    }
  }
</style>
