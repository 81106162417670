import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import vueDebounce from 'vue-debounce'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import en from '@/locales/en.json'
import nl from '@/locales/nl.json'
import fr from '@/locales/fr.json'
import VueI18n from 'vue-i18n'
import { ServiceFactory } from '@/services/ServiceFactory'
import { can, Permission } from '@/composables/useGuard'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(vueDebounce)
Vue.component('VSelect', vSelect)

export const isRole = (role: string) =>
  ServiceFactory.createPermissionService().is(role)

Vue.mixin({
  methods: {
    navigateTo(route) {
      return this.$router.push(route)
    },

    can(permission: Permission) {
      return can(permission)
    },

    is(role) {
      return ServiceFactory.createPermissionService().is(role)
    },

    hasOption(name: string): boolean {
      switch (name) {
        case 'basicOptIn':
          return store.state.basicOptIn
        case 'multiLanguage':
          return store.state.multiLanguage
        case 'issuedMaterials':
          return store.state.issuedMaterials
        case 'interactionNote':
          return store.state.interactionNote
        case 'clientNote':
          return store.state.clientNote
        default:
          Sentry.captureException(
            new Error(`No option configured for "${name}"`),
          )
          return false
      }
    },

    hasModule(name: string): boolean {
      switch (name) {
        case 'optIn':
          return store.state.modules.optIn || false
        case 'cycle':
          return store.state.modules.cycle || false
        case 'tier':
          return store.state.modules.tier || false
        case 'target':
          return store.state.modules.target || false
        case 'rayon':
          return store.state.modules.rayon || false
        case 'activity':
          return store.state.modules.activity || false
        case 'omnichannel':
          return store.state.modules.omnichannel || false
        default:
          Sentry.captureException(
            new Error(`No module configured for "${name}"`),
          )
          return false
      }
    },
  },
})

axios.interceptors.request.use((config) => {
  const token = store.state.accessToken

  if (token) config.headers.Authorization = `Bearer ${token}`

  return config
}, null)

axios.interceptors.response.use(null, (error) => {
  if (401 === error.response.status) {
    store.commit({
      type: 'setAuthenticatedUser',
      authenticatedUser: false,
    })

    store.commit({
      type: 'setAccessToken',
      accessToken: '',
    })

    location.reload()
  }

  if (403 === error.response.status) {
    location.href = '/403'
  }
})

axios.defaults.baseURL = process.env.VUE_APP_API_URL

const fallbackLocale = process.env.VUE_APP_DEFAULT_LANGUAGE
let locale = fallbackLocale

if (store?.state?.authenticatedUser?.language) {
  locale = store.state.authenticatedUser.language
}

const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages: {
    nl,
    en,
    fr,
  },
})

if (process.env.VUE_APP_SENTRY_DSN) {
  console.debug(
    'Initializing Sentry with DSN: ' + process.env.VUE_APP_SENTRY_DSN,
  )

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [],
    tracesSampleRate: 1,
  })
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
