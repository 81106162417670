<template>
  <div class="rep-target-widget">
    <template v-if="targets.length > 0">
      <table style="width: 100%">
        <thead>
          <tr style="text-align: left">
            <th>Label</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Percentage</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="target in targets">
            <td>{{ target.name }}</td>
            <td>{{ target.planned }}</td>
            <td>{{ target.covered }}</td>
            <td>{{ target.percentage.toFixed(1) }}%</td>
          </tr>

          <tr style="height: 1rem"></tr>
        </tbody>

        <tfoot>
          <tr>
            <td style="border-top: 1px solid black" colspan="1"></td>

            <td style="border-top: 1px solid black">{{ totalPlanned }}</td>

            <td style="border-top: 1px solid black">{{ totalCovered }}</td>

            <td style="border-top: 1px solid black">
              {{ totalPercentage.toFixed(1) }}%
            </td>
          </tr>
        </tfoot>
      </table>
    </template>

    <template v-else> Er zijn nog geen targets gedefinieerd. </template>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'RepTargetWidget',

    props: ['targets'],

    computed: {
      totalPlanned(): number {
        return this.targets.reduce((total, target) => {
          return (total += target.planned)
        }, 0)
      },

      totalCovered(): number {
        return this.targets.reduce((total, target) => {
          return (total += target.covered)
        }, 0)
      },

      totalPercentage(): number {
        return (this.totalCovered * 100) / this.totalPlanned || 0
      },
    },

    methods: {
      getLabelName(labels) {
        if (labels.length < 1) {
          return ''
        }

        return `(${labels[0].name})`
      },
    },
  })
</script>
