<template>
  <div v-if="!isLoading" id="app">
    <nav class="top-bar">
      <RouterLink to="/">
        <img class="interact-logo" src="/img/arrows.png" />
        <div class="branding">{{ appName }}</div>
      </RouterLink>

      <div class="menu-toggle" data-qa="menu-toggle" @click="toggleMenu()">
        Menu
      </div>

      <div class="account">
        <RouterLink to="/account" data-qa="account-button">
          <i class="fa fa-user"></i>
        </RouterLink>
      </div>
    </nav>

    <div class="main">
      <nav class="menu" :class="{ 'is-open': isMenuOpen }" @click="hideMenu()">
        <RouterLink to="/" class="branding-block">
          <img class="interact-logo" src="/img/arrows-white.png" />
          <div class="branding">{{ appName }}</div>
        </RouterLink>

        <RouterLink to="/" data-qa="dashboard-link">
          {{ $t('menu.dashboard') }}
        </RouterLink>

        <RouterLink to="/klanten" data-qa="clients-link">
          {{ $t('menu.clients') }}
        </RouterLink>

        <RouterLink
          v-if="authenticated"
          to="/locaties"
          data-qa="locations-link"
        >
          {{ $t('menu.locations') }}
        </RouterLink>

        <RouterLink to="/interacties" data-qa="interactions-link">
          {{ $t('menu.interactions') }}
        </RouterLink>

        <RouterLink
          v-if="authenticated && can('edit products')"
          to="/producten"
        >
          {{ $t('menu.products') }}
        </RouterLink>

        <RouterLink
          v-if="
            (authenticated && is('Super Admin')) || can('manage properties')
          "
          to="/eigenschappen"
        >
          {{ $t('menu.properties') }}
        </RouterLink>

        <RouterLink
          v-if="hasOption('issuedMaterials')"
          to="/afgegeven-materiaal"
        >
          {{ $t('menu.issuedMaterial') }}
        </RouterLink>

        <RouterLink
          v-if="hasModule('omnichannel')"
          to="/omnichannel/email-templates"
        >
          {{ $t('menu.emailTemplates') }}
        </RouterLink>

        <RouterLink v-if="hasModule('omnichannel')" to="/omnichannel/emails">
          {{ $t('menu.emails') }}
        </RouterLink>

        <RouterLink v-if="authenticated && can('see rayons')" to="/reps">
          {{ $t('menu.reps') }}
        </RouterLink>

        <template v-if="hasModule('activity')">
          <RouterLink to="/dagbesteding">
            {{ $t('menu.activities') }}
          </RouterLink>
        </template>

        <RouterLink v-if="authenticated && is('Super Admin')" to="/admin">
          {{ $t('menu.adminPanel') }}
        </RouterLink>

        <div class="version">
          <div v-if="version">Versie: {{ version }}</div>

          <div v-if="lastImportDate">Laatste import: {{ lastImportDate }}</div>
        </div>
      </nav>

      <div class="pusher"></div>

      <div class="content">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import SettingsService from '@/services/settings.service'
  import store from '@/store'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data() {
      return {
        isMenuOpen: false,
        isLoading: true,
        authenticated: false,
        // TODO: Move to backend
        appName: process.env.VUE_APP_NAME,
        strategy: process.env.VUE_APP_INTERACTION_DETAILS,
        lastImportDate: this.$store.state.lastImportDate ?? '',
        version: process.env.PACKAGE_VERSION,
      }
    },
    async mounted() {
      const html = document.documentElement

      await new SettingsService().getAll().then((response) => {
        store.commit({
          type: 'setModules',
          modules: response.data.modules,
        })

        // TODO: Make 1 settings object

        if (response.data?.lastImport) {
          store.commit({
            type: 'setLastImportDate',
            lastImportDate: response.data.lastImport.date,
          })
        }

        store.commit({
          type: 'setBasicOptIn',
          basicOptIn: response.data?.basicOptIn ?? false,
        })

        store.commit({
          type: 'setMultiLanguage',
          multiLanguage: response.data?.multiLanguage ?? false,
        })

        store.commit({
          type: 'setIssuedMaterials',
          issuedMaterials: response.data?.issuedMaterials ?? false,
        })

        store.commit({
          type: 'setInteractionNote',
          interactionNote: response.data?.interactionNote ?? true,
        })

        store.commit({
          type: 'setClientNote',
          clientNote: response.data?.clientNote ?? true,
        })

        this.isLoading = false
      })

      html.setAttribute('lang', this.$i18n.locale)

      document.addEventListener('authenticated', () => {
        this.authenticated = true
        this.$forceUpdate()
      })

      if (this.$store.state.authenticatedUser) {
        this.authenticated = true
      }
    },

    methods: {
      hideMenu() {
        this.isMenuOpen = false
      },
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen
      },
    },
  })
</script>

<style lang="scss">
  @import 'src/variables';
  $grid-gutter-width: 1.5rem;
  @import 'node_modules/bootstrap/scss/bootstrap-grid';

  html {
    box-sizing: border-box;
    font-size: 14.5px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-size: 1rem;
    color: $primary-font-color;
    background: rgb(250, 250, 250);
    background: linear-gradient(
      47deg,
      rgba(250, 250, 250, 1) 0%,
      rgba(238, 238, 238, 1) 49%
    );
    overflow-x: hidden;
  }

  header {
    overflow: hidden;
  }

  .ce-settings__plugin-zone {
    display: none;
  }

  .row {
    .card {
      height: calc(100% - 1.5rem);
    }
  }

  .interact-logo {
    height: 3.75rem;
    padding: 0.75rem 0;
    margin-right: 0.25rem;
  }

  .branding {
    line-height: 3.75rem;
    display: inline-block;
    float: right;
    margin-left: 0.25rem;
    font-weight: 700;
  }

  .content {
    max-width: calc(100vw - 15rem);

    @media (max-width: $breakpoint) {
      max-width: 100vw;
    }
  }

  .main {
    display: flex;
    min-height: calc(100vh - 3.75rem);

    nav.menu,
    .content {
      display: flex;
      flex-direction: column;
    }

    .content {
      padding: 2rem 2rem 3rem 2rem;
      flex-grow: 1;

      h1 {
        line-height: 2rem;
        margin: 0 0 1.5rem 0;
      }

      @media (max-width: $breakpoint) {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      }
    }
  }

  nav {
    z-index: 9000;

    &.top-bar {
      display: flex;
      align-items: center;
      background-color: #fff;
      width: 100%;
      height: 3.75rem;
      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
      padding: 0 1.5rem;

      a {
        text-decoration: none;
        color: $primary-font-color;
      }

      .branding {
        font-size: 1.1rem;
        font-weight: 700;
      }
    }

    &.menu {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 15rem;
      min-width: 15rem;
      background-color: $primary-color;
      background-image: linear-gradient(
        180deg,
        $primary-color 10%,
        $primary-color-dark-fade 100%
      );
      list-style: none;

      .branding-block {
        background-color: #0088c1;
        height: 3.75rem;
        margin-bottom: 1rem;
      }

      .version {
        position: absolute;
        bottom: 1rem;
        text-align: center;
        font-size: 0.8rem;
        width: 100%;
        color: #fff;
      }

      a {
        position: relative;
        color: #fff;
        text-decoration: none;
        display: flex;
        align-items: center;
        min-height: 3rem;
        padding: 1rem 2rem;
        transition: all 0.25s ease-in-out;

        &:not(.branding-block):not(:last-child):after {
          content: '';
          height: 1px;
          background-color: rgba(255, 255, 255, 0.1);
          width: 75%;
          position: absolute;
          bottom: 0;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      @media (max-width: $breakpoint) {
        left: -15rem;
        opacity: 0;
        transition: all 0.25s ease-in-out;

        &.is-open {
          left: 0;
          opacity: 1;
        }
      }
    }
  }

  .account {
    display: flex;
    align-self: center;
    background-color: $primary-color;
    color: #fff;
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    border-radius: 50%;
    margin-left: auto;
    font-weight: 700;
    transition: 0.25s all ease-in-out;

    &:hover {
      opacity: 0.75;
    }

    @media (max-width: $breakpoint) {
      margin-left: 1rem;
    }

    a {
      text-decoration: none;
      color: #fff !important;
      width: 100%;
      text-align: center;
    }
  }

  .pusher {
    width: 15rem;

    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  .menu-toggle {
    display: none;

    @media (max-width: $breakpoint) {
      display: flex;
      align-self: center;
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
