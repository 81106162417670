<template>
  <h1>
    {{ title }}
  </h1>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'PageTitle',
    props: {
      title: {
        required: true,
      },
    },
  })
</script>
