<template>
  <div class="filter">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DataFilter',
  })
</script>

<style lang="sass" scoped>
  .filter
    display: inline-block
    width: 100%
    margin-bottom: .5rem

    input[type=date],
    select
      font-family: Nunito,sans-serif
      width: 100%
      margin-right: .75rem
      border: 1px solid #ddd

    label
      width: 100%
      display: inline-block
      font-size: .9rem
      margin-top: 5px
      margin-right: .25rem
      margin-bottom: 5px
</style>
