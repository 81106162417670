import axios from 'axios'

export default class ProductService {
  public getAll() {
    return axios.get('/api/v1/products/')
  }

  public getById(id) {
    return axios.get(`/api/v1/products/${id}`)
  }

  public create(product) {
    return axios.post('/api/v1/products', product)
  }

  public update(product) {
    return axios.put(`/api/v1/products/${product.id}`, product)
  }
}
