import axios from 'axios'

export const createClient = (client) => axios.post('/api/v1/clients', client)

export const getClientById = (id: number) => axios.get(`/api/v1/clients/${id}`)

export const updateClient = (client) =>
  axios.post(`/api/v1/clients/${client.id}`, client)

export const changeTargetOfClient = (id: number, target: boolean) =>
  axios.post(`/api/v1/clients/${id}/target`, { target })
