import axios, { AxiosResponse } from 'axios'

export default class SettingsService {
  public getAll(): Promise<AxiosResponse> {
    return axios.get('/api/v1/settings')
  }

  public update(settings): Promise<AxiosResponse> {
    return axios.put('/api/v1/settings', settings)
  }

  public getMaterialLimit(): Promise<AxiosResponse> {
    return axios.get('/api/v1/material-limit')
  }
}
