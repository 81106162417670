import ClientService from '@/services/client.service'
import RepService from '@/services/rep.service'
import BrickService from '@/services/brick.service'
import RayonService from '@/services/rayon.service'
import ClientTypeService from '@/services/client-type.service'
import ProductService from '@/services/product.service'
import TierService from '@/services/tier.service'
import LabelService from '@/services/label.service'
import CycleService from '@/services/cycle.service'
import InteractionService from '@/services/interaction.service'
import { IssuedMaterialService } from '@/services/issued-material.service'
import ReasonService from '@/services/reason.service'
import StatisticsService from '@/services/statistics.service'
import InteractionTypeService from '@/services/interaction-type.service'
import TargetService from '@/services/target.service'
import SettingsService from '@/services/settings.service'
import PermissionService from '@/services/permission.service'
import LocationService from '@/services/LocationService'

export class ServiceFactory {
  static createClientService(): ClientService {
    return new ClientService()
  }

  static createRepService(): RepService {
    return new RepService()
  }

  static createBrickService(): BrickService {
    return new BrickService()
  }

  static createRayonService(): RayonService {
    return new RayonService()
  }

  static createClientTypeService(): ClientTypeService {
    return new ClientTypeService()
  }

  static createProductService(): ProductService {
    return new ProductService()
  }

  static createTargetService(): TargetService {
    return new TargetService()
  }

  static createTierService(): TierService {
    return new TierService()
  }

  static createLabelService(): LabelService {
    return new LabelService()
  }

  static createCycleService(): CycleService {
    return new CycleService()
  }

  static createInteractionService(): InteractionService {
    return new InteractionService()
  }

  static createInteractionTypeService(): InteractionTypeService {
    return new InteractionTypeService()
  }

  static createIssuedMaterialService(): IssuedMaterialService {
    return new IssuedMaterialService()
  }

  static createReasonService(): ReasonService {
    return new ReasonService()
  }

  static createStatisticsService(): StatisticsService {
    return new StatisticsService()
  }

  static createSettingsService(): SettingsService {
    return new SettingsService()
  }

  static createPermissionService(): PermissionService {
    return new PermissionService()
  }

  static createLocationService(): LocationService {
    return new LocationService()
  }
}
