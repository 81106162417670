<template>
  <TableContainer v-if="!loading" class="count-per-interaction-type-widget">
    <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Aantal</th>
          <th>Percentage</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(type, index) in stats.types">
          <td>{{ index }}</td>
          <td>{{ type.count }}</td>
          <td>{{ type.percentage }}%</td>
        </tr>
        <tr></tr>
      </tbody>

      <tfoot>
        <tr>
          <td>
            <strong>Totaal:</strong>
          </td>
          <td>{{ stats.total }}</td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </TableContainer>
</template>

<script lang="ts">
  import TableContainer from '@/components/TableContainer.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CountPerInteractionTypeWidget',
    components: {
      TableContainer,
    },
    props: {
      id: Number,
      cycleId: Number,
    },

    data() {
      return {
        stats: [],
        loading: true,
      }
    },

    computed: {
      average() {
        if (this.isInt(this.stats.average)) return this.stats.average

        return this.stats.average.toFixed(1)
      },
    },

    watch: {
      cycleId() {
        this.update()
      },
    },

    async beforeMount() {
      await this.update()
      this.loading = false
    },

    methods: {
      isInt(n) {
        return n % 1 === 0
      },
      update() {
        ServiceFactory.createStatisticsService()
          .countPerType(this.id, this.cycleId)
          .then((response) => (this.stats = response.data))
      },
    },
  })
</script>
