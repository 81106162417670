import axios from 'axios'

export default class ClientTypeService {
  public getAll() {
    return axios.get('/api/v1/client-types/')
  }

  public getPrimary() {
    return axios.get('/api/v1/client-types/', {
      params: {
        relation: 'primary',
      },
    })
  }

  public getSecondary(clientTypeId?: number) {
    return axios.get('' + '/api/v1/client-types/', {
      params: {
        relation: 'secondary',
        clientTypeId,
      },
    })
  }

  public getPrimaryStatic() {
    return axios.get('/api/v1/client-types/primary')
  }

  public getSecondaryStatic() {
    return axios.get('/api/v1/client-types/secondary')
  }
}
