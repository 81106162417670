<template>
  <div class="rep-evening-stats-widget">
    <template v-if="hasEveningStats">
      <table style="width: 100%">
        <thead>
          <tr style="text-align: left">
            <th>Reden</th>
            <th>Aantal dagdelen</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Congres</td>
            <td>{{ totalCongress }}</td>
          </tr>

          <tr>
            <td>Meeting</td>
            <td>{{ totalMeeting }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <strong>Totaal:</strong>
            </td>

            <td>{{ eveningStateTotal }}</td>
          </tr>
        </tfoot>
      </table>
    </template>

    <template v-else> Er zijn nog geen avonden ingevoerd. </template>
  </div>
</template>

<script lang="ts">
  import StatisticsService from '@/services/statistics.service'
  import { defineComponent } from 'vue'

  const statisticsService = new StatisticsService()

  export default defineComponent({
    name: 'RepEveningStatsWidget',

    props: {
      id: Number,
    },

    data() {
      return {
        eveningStats: [],
      }
    },

    computed: {
      hasEveningStats() {
        if (!this.eveningStats) return false

        return this.eveningStats.congres || this.eveningStats.meeting
      },

      totalCongress() {
        return this.eveningStats.congres ? this.eveningStats.congres : 0
      },

      totalMeeting() {
        return this.eveningStats.meeting ? this.eveningStats.meeting : 0
      },

      eveningStateTotal() {
        return this.totalCongress + this.totalMeeting
      },
    },

    watch: {
      cycleId() {
        statisticsService
          .eveningStats(this.id, this.cycleId)
          .then((response) => (this.eveningStats = response.data))
      },
    },

    beforeMount() {
      statisticsService
        .eveningStats(this.id, this.cycleId)
        .then((response) => (this.eveningStats = response.data))
    },
  })
</script>
