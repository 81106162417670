<template>
  <div v-if="id || selectedCycleId" class="rep-dashboard">
    <Card v-if="userHasTargets">
      <template #header>
        <h2>Targets</h2>
      </template>

      <RepTargetWidget :targets="targets" />
    </Card>

    <Card>
      <template #header>
        <h2>Interactietypes</h2>
      </template>

      <CountPerInteractionTypeWidget :id="id" :cycle-id="selectedCycleId" />
    </Card>

    <div class="row">
      <div v-if="false" class="col-md-6">
        <Card>
          <h2>Bezoekgemiddelde</h2>
          <InteractionAverageWidget :id="id" :cycle-id="selectedCycleId" />
        </Card>
      </div>

      <div v-if="strategy === 'tramedico' && false" class="col-md-6">
        <Card>
          <h2>Ingevoerde avonden</h2>
          <RepEveningStatsWidget :id="id" :cycle-id="selectedCycleId" />
        </Card>
      </div>
    </div>

    <Card v-if="userHasTargets">
      <template #header>
        <h2>Interactietypes per target</h2>
      </template>
      <TypesPerTargetWidget :id="id" :cycle-id="selectedCycleId" />
    </Card>

    <div v-if="userHasTargets" class="row">
      <div class="col-12">
        <h2>Coverage</h2>
      </div>
      <template v-for="target in targets">
        <div class="col-lg-3 col-md-6 col-xs-6">
          <Card>
            <h3>{{ target.name }}</h3>
            <RepCoverageWidget :id="id" :target="target" />
          </Card>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import Card from '@/components/Card.vue'
  import RepTargetWidget from '@/components/RepTargetWidget.vue'
  import RepEveningStatsWidget from '@/components/RepEveningStatsWidget.vue'
  import RepCoverageWidget from '@/components/RepCoverageWidget.vue'
  import InteractionAverageWidget from '@/components/InteractionAverageWidget.vue'
  import TypesPerTargetWidget from '@/components/TypesPerTargetWidget.vue'
  import CountPerInteractionTypeWidget from '@/components/CountPerInteractionTypeWidget.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'RepDashboard',

    components: {
      Card,
      RepTargetWidget,
      RepEveningStatsWidget,
      RepCoverageWidget,
      InteractionAverageWidget,
      CountPerInteractionTypeWidget,
      TypesPerTargetWidget,
    },

    props: {
      id: Number,
      selectedCycleId: Number,
    },

    data() {
      return {
        targets: [],
        strategy: process.env.VUE_APP_INTERACTION_DETAILS,
      }
    },

    computed: {
      userHasTargets(): boolean {
        return this.targets.length > 0
      },
    },

    watch: {
      selectedCycleId() {
        this.updateTargets()
      },
    },

    async beforeMount() {
      await this.updateTargets()
    },

    methods: {
      updateTargets() {
        ServiceFactory.createTargetService()
          .getByUserId(this.id, this.selectedCycleId)
          .then((response) => (this.targets = response.data))
      },
      getLabelName(labels) {
        if (labels.length < 1) return ''

        return `(${labels[0].name})`
      },
    },
  })
</script>
