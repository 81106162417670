<template>
  <div class="types-per-target-widget">
    <template v-if="targets.length > 0">
      <table style="width: 100%">
        <thead>
          <tr style="text-align: left">
            <th>Label</th>
            <th>Type</th>
            <th>Aantal</th>
            <th>Percentage</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="target in targets">
            <tr>
              <td style="border-bottom: 1px solid black">
                {{ target.name }}
              </td>

              <td style="border-bottom: 1px solid black"></td>

              <td style="border-bottom: 1px solid black">
                {{ target.total }}
              </td>

              <td style="border-bottom: 1px solid black"></td>
            </tr>

            <tr v-for="spec in target.specs">
              <td colspan="1"></td>
              <td>{{ spec.type }}</td>
              <td>{{ spec.amount }}</td>
              <td>{{ spec.percentage.toFixed(1) }}%</td>
            </tr>

            <tr style="height: 1rem"></tr>
          </template>
        </tbody>
      </table>
    </template>

    <template v-else> Er zijn nog geen targets gedefinieerd. </template>
  </div>
</template>

<script lang="ts">
  import StatisticsService from '@/services/statistics.service'
  import { defineComponent } from 'vue'

  const statisticsService = new StatisticsService()

  export default defineComponent({
    name: 'TypesPerTargetWidget',

    props: {
      id: Number,
      cycleId: Number,
    },

    data() {
      return {
        targets: [],
      }
    },

    watch: {
      cycleId() {
        statisticsService
          .typesPerTarget(this.id, this.cycleId)
          .then((response) => {
            this.targets = response.data
          })
      },
    },

    beforeMount() {
      statisticsService
        .typesPerTarget(this.id, this.cycleId)
        .then((response) => {
          this.targets = response.data
        })
    },
  })
</script>
