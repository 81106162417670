<template>
  <div class="rep-coverage-widget">
    <canvas :id="chartId" width="100%" height="auto"></canvas>

    <div class="legend">
      <strong>Gecoverd: {{ target.covered }}</strong>
      <strong>Niet gecoverd: {{ notVisited }}</strong>
    </div>
  </div>
</template>

<script lang="ts">
  import Chart from 'chart.js'
  import { v4 as uuid } from 'uuid'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'RepCoverageWidget',

    props: ['id', 'target'],

    data() {
      return {
        chartId: uuid(),
        stats: [],
      }
    },

    computed: {
      notVisited() {
        return this.target.planned - this.target.covered
      },
    },

    mounted() {
      const canvas = document.getElementById(this.chartId) as HTMLCanvasElement
      const ctx = canvas.getContext('2d')
      const chart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Gecoverd', 'Niet gecoverd'],
          datasets: [
            {
              data: [
                this.target.covered,
                this.target.planned - this.target.covered,
              ],
              backgroundColor: ['rgba(255, 99, 132, 1)'],
            },
          ],
        },
      })

      canvas.onclick = (evt) => {
        const activePoints = chart.getElementsAtEvent(evt)
        const visited = activePoints[0]._model.label === 'Gecoverd'
        const query = {} as { rep; visited; label; target }

        query.rep = this.id
        query.visited = visited
        query.label = parseInt(this.target.label_ids, 10)
        query.target = 'true'

        this.$router.push({
          path: '/klanten',
          query,
        })
      }
    },
  })
</script>

<style lang="sass" scoped>
  .legend
    text-align: right

    strong
      display: block
      font-size: .7rem

      &:first-child
        margin-top: .5rem
</style>
