<template>
  <div v-if="user" class="home">
    <PageHeader>
      <PageTitle :title="$t('dashboard.title')" />
    </PageHeader>

    <div class="row">
      <div class="col">
        <Card>
          <div class="row">
            <DataFilter
              v-if="!is('Rep') && reps.length > 0 && can('see rayons')"
              class="col-sm-4"
            >
              <label> {{ $t('generic.rep') }}: </label>

              <select v-model="id">
                <option :value="0">
                  {{ $t('generic.all') }}
                </option>

                <option v-for="rep in reps" :value="rep.id">
                  {{ rep.name }}
                </option>
              </select>
            </DataFilter>

            <DataFilter class="col-sm-4">
              <label>{{ $t('generic.cycle') }}: </label>

              <select v-model="selectedCycleId">
                <option v-for="cycle in cycles" :value="cycle.id">
                  {{ cycle.name }}
                </option>
              </select>
            </DataFilter>

            <DataFilter v-if="lastInteraction" class="col-sm-4">
              <label>{{ $t('dashboard.lastInteraction') }}: </label>
              <span>{{ lastInteraction.occurred_at }}</span>
            </DataFilter>
          </div>
        </Card>
      </div>
    </div>

    <template v-if="showAll">
      <Card v-if="hasModule('activity') && false">
        <h2>{{ $t('generic.activities') }}</h2>

        <TableContainer>
          <table>
            <thead>
              <tr>
                <th></th>
                <th v-for="rep in repsForSums" :key="rep">
                  {{ rep }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="activityType in activityTypes">
                <td>{{ activityType.name }}</td>

                <td v-for="rep in repsForSums" :key="rep">
                  {{ getActivity(rep, activityType.name) }}
                </td>
              </tr>
            </tbody>
          </table>
        </TableContainer>
      </Card>

      <Card>
        <h2>{{ $t('menu.interactions') }}</h2>

        <TableContainer>
          <table>
            <thead>
              <tr>
                <th></th>
                <th v-for="rep in repsForSums" :key="rep">
                  {{ rep }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="interactionType in interactionTypes">
                <td>{{ interactionType.name }}</td>
                <td v-for="rep in repsForSums" :key="rep">
                  {{ getInteractionStatistic(rep, interactionType.name) }}
                </td>
              </tr>
            </tbody>
          </table>
        </TableContainer>
      </Card>
    </template>

    <template v-else>
      <RepDashboard
        v-if="id && selectedCycleId"
        :id="id"
        :key="id"
        :selected-cycle-id="selectedCycleId"
      ></RepDashboard>
    </template>
  </div>
</template>

<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  import RepDashboard from '@/components/RepDashboard.vue'
  import Card from '@/components/Card.vue'
  import TableContainer from '@/components/TableContainer'
  import DataFilter from '@/components/DataFilter'
  import PageHeader from '@/components/PageHeader'
  import PageTitle from '@/components/PageTitle'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Home',

    components: {
      Card,
      RepDashboard,
      TableContainer,
      DataFilter,
      PageHeader,
      PageTitle,
    },

    data() {
      return {
        user: null,
        id: null,
        reps: [],
        cycles: [],
        activities: [],
        interactionStatistics: [],
        interactionTypes: [],
        activityTypes: [],
        lastInteraction: null,
        strategy: process.env.VUE_APP_INTERACTION_DETAILS,
        selectedCycleId: null,
      }
    },
    computed: {
      repsForSums() {
        return Object.keys(this.interactionStatistics)
      },
      currentCycle() {
        return this.cycles.find((cycle) => cycle.is_current) ?? null
      },
      showAll() {
        return this.id === 0
      },
    },
    watch: {
      selectedCycleId() {
        this.updateLastInteraction()
        this.updateActivityStatistics()
        this.updateInteractionStatistics()
      },
      id() {
        this.updateLastInteraction()
      },
    },
    async beforeMount() {
      this.user = this.$store.state.authenticatedUser

      this.id = this.is('Rep') ? this.user.id : 0

      ServiceFactory.createReasonService()
        .getAll()
        .then((response) => (this.activityTypes = response.data))
      ServiceFactory.createInteractionTypeService()
        .getAll()
        .then((response) => (this.interactionTypes = response.data))

      if (!this.showAll) {
        await ServiceFactory.createCycleService()
          .getAll()
          .then((response) => {
            this.cycles = response.data
            this.cycles.forEach((cycle) => {
              if (cycle.is_current) this.selectedCycleId = cycle.id
            })
          })
        this.updateLastInteraction()
      } else {
        Promise.all([
          ServiceFactory.createRepService().getAll(),
          ServiceFactory.createCycleService().getAll(),
          ServiceFactory.createStatisticsService().activitySumPerRep(),
          ServiceFactory.createStatisticsService().interactionTypePerRep(),
        ]).then((responses) => {
          const [
            repResponse,
            cycleResponse,
            activityStatisticsResponse,
            interactionStatisticsResponse,
          ] = responses

          this.reps = repResponse.data
          this.cycles = cycleResponse.data
          this.activities = activityStatisticsResponse.data
          this.interactionStatistics = interactionStatisticsResponse.data

          this.cycles.forEach((cycle) => {
            if (cycle.is_current) this.selectedCycleId = cycle.id
          })
        })
      }
    },
    methods: {
      getActivity(rep, type) {
        if (!this.activities.hasOwnProperty(rep)) return 0

        if (!this.activities[rep].hasOwnProperty(type)) return 0

        return this.activities[rep][type]
      },
      getInteractionStatistic(rep, type) {
        if (!this.interactionStatistics.hasOwnProperty(rep)) return 0

        if (!this.interactionStatistics[rep].hasOwnProperty(type)) return 0

        return this.interactionStatistics[rep][type]
      },
      updateLastInteraction() {
        ServiceFactory.createInteractionService()
          .last(this.id, this.selectedCycleId)
          .then((lastInteractionResponse) => {
            this.lastInteraction = lastInteractionResponse.data.interaction
          })
      },
      updateActivityStatistics() {
        ServiceFactory.createStatisticsService()
          .activitySumPerRep(this.selectedCycleId)
          .then((response) => (this.activities = response.data))
      },
      updateInteractionStatistics() {
        ServiceFactory.createStatisticsService()
          .interactionTypePerRep(this.selectedCycleId)
          .then((response) => (this.interactionStatistics = response.data))
      },
    },
  })
</script>

<style lang="sass" scoped>
  @import 'src/variables'

  .meta
    .card
      display: inline-block
      width: calc(50% - .5rem)
      margin-right: 1rem
      min-height: 240px

      &:last-of-type
        margin-right: 0

  .coverages
    .card
      display: inline-block
      width: calc(25% - .75rem)
      margin-right: 1rem

      @media (max-width: 700px)
        width: calc(50% - .5rem)

        &:nth-child(2)
          margin-right: 0

      @media (max-width: 400px)
        width: 100%
        margin-right: 0

      &:last-of-type
        margin-right: 0
</style>
