import axios, { AxiosResponse } from 'axios'

export default class LocationService {
  public getAll(): Promise<AxiosResponse> {
    return axios.get('/api/v1/locations')
  }

  public getById(id: number): Promise<AxiosResponse> {
    return axios.get(`/api/v1/locations/${id}`)
  }

  public changeNote(id: number, note: string): Promise<AxiosResponse> {
    return axios.post(`/api/v1/locations/${id}/note`, {
      note,
    })
  }

  public delete(id: number): Promise<AxiosResponse> {
    return axios.delete(`/api/v1/locations/${id}`)
  }
}
