import axios, { AxiosResponse } from 'axios'
import { RepInterface } from '@/models/RepInterface'

export default class RepService {
  public getById(id): Promise<AxiosResponse<RepInterface>> {
    return axios.get(`/api/v1/reps/${id}`)
  }

  public getAll(): Promise<AxiosResponse<RepInterface[]>> {
    return axios.get('/api/v1/reps/')
  }
}
