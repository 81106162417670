import { ServiceFactory } from '@/services/ServiceFactory'

export type Permission =
  | 'edit products'
  | 'see rayons'
  | 'manage clients'
  | 'delete locations'
  | 'manage properties'
  | 'assign properties'
  | 'import clients'

export const can = (permission: Permission): boolean =>
  ServiceFactory.createPermissionService().can(permission)

export const canEditProducts = () => can('edit products')

export const canManageClients = () => can('manage clients')
export const canAssignProperties = () => can('assign properties')
