<template>
  <div class="card">
    <header v-if="hasHeaderSlot">
      <slot name="header" />
    </header>

    <div class="body">
      <slot />
    </div>

    <footer v-if="hasFooterSlot">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Card',
    computed: {
      hasHeaderSlot(): boolean {
        return !!this.$slots.header
      },
      hasFooterSlot(): boolean {
        return !!this.$slots.footer
      },
    },
  })
</script>

<style lang="sass" scoped>
  @use "sass:math"
  @import "src/variables"

  .card
    width: 100%
    background-color: #fff
    border-radius: $default-border-radius
    padding: $default-space
    margin-bottom: $default-space
    overflow: hidden
    box-shadow: 0 3px 10px rgba(0, 0, 0, .1)

    h2
      line-height: 1.25rem

    h2 + .table-container
      margin-top: -1.5rem

    header
      padding-bottom: math.div($default-space, 2)

    header,
    .body,
    footer
      *:first-child
        margin-top: 0

      *:last-child
        margin-bottom: 0

    h4
      margin-bottom: .25rem

    p
      margin-top: 0

    footer
      padding-top: math.div($default-space, 2)

    form
      margin-bottom: .5rem

      .form-group
        margin-bottom: 1rem

        &:last-of-type
          margin-bottom: 0

      label
        display: inline-block
        width: 100%
        margin-bottom: .25rem
        font-weight: bold

      input:not([type=checkbox])
        font-family: 'Nunito', sans-serif
        border: 1px solid #eee
        font-size: .75rem
        height: 2rem
        padding: 1.25rem .75rem
        border-radius: $default-border-radius

      select
        font-family: 'Nunito', sans-serif
        width: 100%
        border: 1px solid #eee
        font-size: .75rem
        height: 2.5rem
        padding: 0 1rem
        border-radius: .25rem
        cursor: pointer
</style>
